<template>
    <div>
        <div class="difficulty">
            <p>难易程度</p>
            <div class="difficultylist clearfix">
                <p class="fl" v-for="res in formatypearr" :key="res.fmttype_id" @click="formatypebtn(res.fmttype_id)" :class="num0 == res.fmttype_id?'active':''" >{{res.fmttype_name}}</p>
            </div>
        </div>
        <div class="topicbox">
            <p>设置试卷<span class="totle">每次选择的试题总数量不能超过100道</span> </p>
            <div class="topicboxlist">
                <div class="clearfix">
                    <p class="fl" v-for="(item,index) in gettypelist" :key="index">
                        <span>{{item.type_name}}</span>
                        <span class="fr shuxue">
                            <span @click="jian(index,sum[index].sun)" :class="sum[index].sun == 0 ? 'disable':''"> <img src="@/assets/image/jian.png" alt=""> </span> 
                            <span> 
                                <input type="number" oninput="if(value.length>2)value=value.slice(0,2)" placeholder="0" v-model="sum[index].sun"/>    
                            </span> 
                            <span @click="jia(index,sum[index].sun)"> <img src="@/assets/image/jia.png" alt=""> </span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="create">
            <p @click="shengcheng">生成试题</p>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    props:['formatypearr','gettypelist','knowledgetoggle','chapteridtoggle'],
    data(){
        return{
            num0:0,
            sum:[],

            // 数据上报字段
            client_ip:'',
        }
    },
    watch:{
        gettypelist(val){
            var that = this
            that.sum = []
            val.forEach(function(res){
                that.sum.push({tixing:res.type_name,id:res.type_id,sun:0})                
            });
        },
        knowledgetoggle(val,old){
            if(val != old){
                this.shengcheng()
            }
        },
        chapteridtoggle(val,old){
            if(val != old){
                this.shengcheng()
            }
        }
    },
    methods: {
        formatypebtn(val){
            var that = this
            this.num0 = val 
            that.$emit('formatype',that.num0)
        },
        jian(e,d){
            if(this.sum[e].sun > 0){
                this.sum[e].sun = Number(d) - 1
            }
        },
        jia(e,d){
            if(this.sum[e].sun < 100){
                this.sum[e].sun = Number(d) + 1
            }
        },
        shengcheng(){   
            var that = this
            var aa = []
            var abc = ''
            that.sum.forEach(function(res){
                if(res.sun > 0){
                    abc = Number(abc) + Number(res.sun)
                    aa.push(res)
                }
            })
            if(abc > 99){
                this.$message({
                    message: '每次选择的试题不能超过100道，请合理搭配试卷',
                    type: 'warning'
                });
            } else {
                that.$emit('plannum',abc)
                that.$emit('questionnum',aa)
            }

            // 数据上报
            // that.generatework()
        },

        // 数据上报
        // 生成试卷
        generatework(){
            var iteminfo = JSON.parse(localStorage.getItem("loginInfo"));
            axios.get("http://access.rujiaowang.net/GetLogServer").then((res) => {
                this.client_ip = res.data.result.client_ip;
                var differenctime = res.data.result.net_ts - Date.parse(new Date()) / 1000;

                var datal = {
                    school_name: iteminfo.teacher_info.school_name
                    ? `${iteminfo.teacher_info.school_name}`
                    : "",
                    school_id: iteminfo.teacher_info.school_id
                    ? `${iteminfo.teacher_info.school_id}`
                    : "",
                    trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),
                }
                var saydata = {
                    product_name: "教师工作台",
                    log_version: "v2.0.1",
                    action: "testgroup_createpapers",
                    user_id: String(iteminfo.user_id),
                    user_role: String(iteminfo.user_type),
                    user_name: String(iteminfo.user_nicename),
                    ts: String(Number(Date.parse(new Date())) / 1000 + differenctime),
                    trace_id: JSON.parse(localStorage.getItem("data_traceid")),
                    dev_id: "",
                    dev_name: "",
                    client_ip: this.client_ip,
                    os: "",
                    cpu_name: "",
                    cpu_use: "",
                    app_version: "v2.0.0",
                    mem_size: "",
                    mem_use: "",
                    net: "",
                    electric: "",
                    extend: "",
                    remark: `${iteminfo.user_nicename}` + "使用了组卷的生成试卷功能",
                    data: datal,
                };
                axios.post("http://47.114.114.56:9002/RecordRjw", saydata).then(res =>{})
            })
        },
    },
}
</script>

<style lang="less" scoped>
.difficulty{
    margin:20px 0;
    padding: 15px 25px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px
                 rgba(200, 200, 200, 0.47);
}
.topicbox{
    margin-bottom: 20px;
    padding: 15px 25px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px
                 rgba(200, 200, 200, 0.47);
    .totle{
        display: inline-block;
        font-size: 12px;
        color: #a3a3a3;
        margin-left: 15px;
    }
}
.difficulty>p,.topicbox>p{    
    margin-bottom: 15px;
    padding-left: 5px;
    border-left: 7px solid #3a8afd;
}
.difficultylist{
    font-size: 16px;
    color: #2f2f2f;
    border-top: 1px solid #eeeeee;
    p{
        margin: 10px 0;
        line-height: 30px;
        width: 68px;
        font-size: 14px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }
}
.topicboxlist{
    &>div{
        padding: 10px;
        p{
            width: 18%;  
            margin: 18px 2% 18px 0;
            font-size: 14px;  
            vertical-align: middle; 
            &:nth-child(5n){
                margin-right: 0;
            }
            span{
                display: inline-block;
            }
            .shuxue{
                display: inline-block;
                width: 48%;
                height: 16px;
                span:first-child,span:nth-child(3){
                    width: 14px;
                    height: 14px;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 100%;
                        vertical-align: middle;
                    }
                } 
                span:nth-child(2){
                    width: 28px;
                    text-align: center;
                    padding: 0 5px;
                    vertical-align: middle;
                    input{
                        border: none;
                        outline: none;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}
.create{
    background: none;
    box-shadow:none;
    margin-bottom: 20px;
    p{
        width: 120px;
        margin: 0 auto;
        text-align: center;
        line-height: 40px;
        background-color: #ff6f6f;
        border-radius: 6px;
        color: #fff;
        cursor: pointer;
    }
}
.active{
    background-color: #3a8afd;
    color: #fff;
    font-size: 16px;
}
.disable {
    pointer-events: none;
    cursor:not-allowed;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>
