import { render, staticRenderFns } from "./hintCopy.vue?vue&type=template&id=2fbfd8b0&scoped=true&"
import script from "./hintCopy.vue?vue&type=script&lang=js&"
export * from "./hintCopy.vue?vue&type=script&lang=js&"
import style0 from "./hintCopy.vue?vue&type=style&index=0&id=2fbfd8b0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fbfd8b0",
  null
  
)

export default component.exports