<template>
<!-- 搜索栏 -->
    <div class="smartChoice">
        <ul>
            <li class="clearfix">
                <div class="one fl">智能选题结果</div>
                <div class="fr">
                    <el-button type="success" @click="quanbu" v-if="centre">全部加入试卷</el-button>
                    <el-button @click="quanbu" class="jinzhi" v-else>全部加入试卷</el-button>
                </div>
            </li>
            <li>
                <div class="title">
                    <span>类型</span>
                    <span>计划选题数</span>
                    <span>实际选题数</span>
                </div>
                <div class="content" v-for="item in tablelist" :key="item.tixing">
                    <span>{{item.tixing}}</span>
                    <span>{{item.jh}}</span>
                    <span>{{item.sj}}</span>
                </div>
            </li>   
            <li class="clearfix">
                <div class="fl">
                    <span>提示：单击题面可显示答案和解析</span>
                </div>
                <div class="fr">
                    <span @click="qiehuan" style=" cursor: pointer;">
                        <!-- <svg class="alicon" aria-hidden="true" v-if="this.radio">
                            <use xlink:href="#iconweixuanzhong"></use>
                        </svg>
                        <svg class="alicon" aria-hidden="true" v-else>
                            <use xlink:href="#iconxuanzhong"></use>
                        </svg> -->
                        显示全部答案与解析
                    </span>
                    &nbsp;&nbsp;
                    <span>共<span>{{papernum}}</span>道试题</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import VueEvent from '@/api/eventBus.js'
export default {
    props:['papernum',"tablelist"],
    data(){
        return{
            radio: true,
            paperlist:[],
            centre:true,
            status:[]
        }
    },
    mounted(){
        var that = this
        VueEvent.$off('huifu')
        VueEvent.$on('huifu',function(data){
            that.quanbuqh(data)
        })
    },
    methods: {
        qiehuan(){
            this.radio = !this.radio
            var that = this
            if(this.radio == false){
                that.status = []
                JSON.parse(localStorage.getItem("paperlist")).forEach(function (res) {
                    that.status.push({status:1})
                });
                VueEvent.$emit('daanzhankai', that.status)
            } else{
                that.status = []
                JSON.parse(localStorage.getItem("paperlist")).forEach(function (res) {
                    that.status.push({status:2})
                });
                VueEvent.$emit('daanzhankai', that.status)
            }
        },
        quanbuqh(data){
            this.centre = data
        },
        quanbu(){
            var that = this
            that.centre = false
            that.paperlist = JSON.parse(localStorage.getItem("paperlist"))
            VueEvent.$emit('quanbushiti', that.paperlist)
        },
    },
}
</script>

<style lang="less" scoped>
.smartChoice{
    padding: 15px 25px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px 
		rgba(200, 200, 200, 0.47);
    ul {
        li {
            font-size: 16px;
            color: #2f2f2f;
            .one {
                padding-left: 5px;
                border-left: 7px solid #3a8afd;
                margin-top: 10px;
            }
            &:nth-child(2) {
                padding-left: 15px;
                width: 70%;
                .title{
                    border-bottom: 1px solid #eee;
                    display: flex;
                    span{
                        flex: 1;
                        display: inline-block;
                        line-height: 50px;
                        font-size: 14px;
                        font-weight: 700;
                        text-align: center;
                    }
                } 
                .content{
                    display: flex;
                    span{
                        flex: 1;
                        display: inline-block;
                        font-size: 14px;
                        line-height: 50px;
                        text-align: center;
                    }
                }
            }
            &:last-child span{
                display: inline-block;
                line-height: 50px;
                font-size: 12px;
                color: #a3a3a3;
            }
        }
    }
}

</style>
