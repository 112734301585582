<template>
  <div>
    <top></top>
    <navMenu></navMenu>
    <div class="inner clearfix">
      <navbar></navbar>
      <div class="fl zjleft">
        <div class="clearfix nav ">
          <p @click="btn(1)" :class="centre == true ? 'active' : ''" class="fl">
            按知识点
          </p>
          <p @click="btn(2)" :class="centre == false ? 'active' : ''" class="fr">
            按章节
          </p>
        </div>
        <knowledge
          v-if="centre == true"
          @zjpointid="zjpointid"
          @zjgrade="zjgrade"
          @zjsid="zjsid"
        ></knowledge>
        <chapterid
          v-else
          @chapterid="chapterid"
          @zjgid="zjgid"
          @zjsidCopy="zjsidCopy"
        ></chapterid>
      </div>
      <div class="fr zjright">
        

        <div v-if="centre == true">

          <topicBox
            @questionnum="questionnum"
            @formatype="formatype"
            :gettypelist="gettypelist"
            :formatypearr="formatypearr"
            :knowledgetoggle="knowledgetoggle"
          ></topicBox>

          <hint v-show="table" :papernum="papernum" :tablelist="tablelist"></hint>

          <paper :paperlist="sxpaperlist"></paper>

        </div>

        <div v-else>

          <topicBox
            @questionnum="questionnumCopy"
            :gettypelist="gettypelistCopy"
            :formatypearr="formatypearr"
            :chapteridtoggle="chapteridtoggle"
          ></topicBox>

          <hint v-show="table" :papernum="papernum" :tablelist="tablelistCopy"></hint>
          
          <paper :paperlist="sxpaperlistCopy"></paper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import top from "@/components/navtop.vue";
import navMenu from "@/components/menuNav.vue";
import URL from "@/api/index";
import knowledge from "../../components/testPaper/knowledge";
import chapterid from "../../components/testPaper/chapterid";
import navbar from "../../components/testPaper/navbar";
import topicBox from "../../components/testPaper/topicBox";
import hint from "../../components/testPaper/hintCopy";
import paper from "../../components/testPaper/paper.vue";
export default {
  components: {
    top,
    navMenu,
    knowledge,
    chapterid,
    navbar,
    topicBox,
    hint,
    paper,
  },
  data() {
    return {
      table:false,
      papernum:0,
      loading:false,
      centre: true,
      // 知识点
      nums:0,
      nums1:0,
      sjlength:0,
      knowledgetoggle:'',
      paperlist: [],
      tablelist: [],
      sxpaperlist: [],
      gettypelist: [],
      paperdata: {
        type: "",
        level: "",
        points: "",
        page: "",
        num: "500",
      },
      knowledgedata: {
        sid: 1,
        scid: "",
      },
      // 章节
      numsCopy:0,
      numsCopy1:0,
      sjlengthCopy:0,
      chapteridtoggle:'',
      paperlistCopy: [],
      tablelistCopy: [],
      sxpaperlistCopy: [],
      gettypelistCopy: [],
      paperdataCopy: {
        type: "",
        level: "",
        chapters: "",
        page: "",
        num: "500",
      },
      chapteriddata: {
        gid: "",
        sid: 1,
      },

      formatypearr: [
        { fmttype_name: "全部", fmttype_id: 0 },
        { fmttype_name: "容易", fmttype_id: 1 },
        { fmttype_name: "较易", fmttype_id: 2 },
        { fmttype_name: "一般", fmttype_id: 3 },
        { fmttype_name: "较难", fmttype_id: 4 },
        { fmttype_name: "困难", fmttype_id: 5 },
      ],
    };
  },
  created() {
    var centre = JSON.parse(localStorage.getItem("centre"))
    if (centre != null) {
      this.centre = centre

      // 数据上报
      var data = {path:'智能选题',id: this.centre == 'false'?'2':'1'}
      localStorage.setItem('optionwork',JSON.stringify(data))
    } else{
      // 数据上报
      var data = {path:'智能选题',id:'1'}
      localStorage.setItem('optionwork',JSON.stringify(data))
    }
  },
  watch:{
    nums(val){
      if(this.sjlength == val - this.nums1){
        // console.log(this.paperlist);
        this.sxpaperlist = this.paperlist
        localStorage.setItem("paperlist", JSON.stringify(this.sxpaperlist))
      }
    },
    numsCopy(val){
      if(this.sjlengthCopy == val - this.numsCopy1){
        this.sxpaperlistCopy = this.paperlistCopy
        localStorage.setItem("paperlist", JSON.stringify(this.sxpaperlistCopy))
      }
    }
  },
  mounted(){
    //试题兼容
    setTimeout(() => {
        this.$nextTick(function () {
            if(this.commonsVariable.isMathjaxConfig){
                this.commonsVariable.initMathjaxConfig();
            }
            // this.commonsVariable.MathQueue("app");//传入组件id，让组件被MathJax渲染
        })
    },1000)
  },
  methods: {
    btn(e) {
      if (e == 1) {
        this.centre = true
        this.sxpaperlist = []
        this.sxpaperlistCopy = []
        this.paperlist = []
        this.tablelist = []
        this.papernum = 0
      } else {
        this.centre = false
        this.sxpaperlist = []
        this.sxpaperlistCopy = []
        this.paperlist = []
        this.tablelist = []
        this.papernum = 0
      }
      localStorage.setItem("centre", JSON.stringify(this.centre))

      // 数据上报
      var data = {path:'智能选题',id: e}
      localStorage.setItem('optionwork',JSON.stringify(data))
    },
    //知识点题型筛选
    zjgrade(val) {
      this.knowledgedata.scid = val
      this.getQuestionType(this.knowledgedata)
      this.sxpaperlist = []
      this.tablelist = []
    },
    zjsid(val) {
      this.knowledgedata.sid = val
      this.getQuestionType(this.knowledgedata)
      this.sxpaperlist = []
      this.tablelist = []
    },
    //知识点试题筛选
    zjpointid(val) {
      // 选题id
      this.knowledgetoggle = val
      this.paperdata.points = val
    },
    formatype(val) {
      // 选题难度
      this.paperdata.level = val
    },
    questionnum(val) {
      var that = this
      that.paperlist = []
      that.tablelist = []
      that.papernum = 0
      that.sjlength = 0
      that.nums = 0
      that.nums1 = 0
      val.forEach(function (res,index) {
        that.paperdata.type = res.id
        that.knowledgelistconhttp(that.paperdata, res.sun,val[index])
      })
      if(val[0] == undefined){
        that.table = false
      } else {
        that.table = true
      }
    },
    getQuestionType(e) {
      //获取知识点题库题型
      var data = {
        sid: e.sid,
        scid: e.scid,
      }
      this.$post(URL.getQuestionType, data).then((res) => {
        this.gettypelist = []
        this.gettypelist = this.gettypelist.concat(res.result)
      })
    },
    // 知识点试题列表
    knowledgelistconhttp(e, d, f) {
      var that = this
      var data = {
        type: e.type,
        level: e.level,
        points: e.points,
        page: e.page,
        num: e.num,
      }
      this.$post(URL.getPointsQues, data).then((res) => {
        var arr = res.result.rows
        var nn = []
        for (var i = 0; i < arr.length; i++) {
          var item = arr[Math.floor(Math.random() * arr.length)]
          nn.push(item)
        }
        // that.paperlist = 
        // html地址解析
        nn.slice(0, d).forEach(function (data, index) {
          var html = data.question_text
          
          var urltext2 = "http://rjwtiku.oss-cn-beijing.aliyuncs.com"
          // store_type 1新 2旧题 判断
          if (data.store_type == 2) {
            var urltext = html.match(/(\S*)SYS/)[1]
            that.$get(data.answer).then((res) => {
              var reg = new RegExp('src="', "g")
              that.realurl2 = res.replace(reg, 'src="' + urltext)
              nn.slice(0, d)[index].answer = that.realurl2
              that.nums++
            })
            that.$get(data.question_text).then((res) => {
              var reg = new RegExp('src="', "g")
              that.realurl2 = res.replace(reg, 'src="' + urltext)
              nn.slice(0, d)[index].question_text = that.realurl2
              that.nums++
            })
            that.nums1++
          } else {
            // console.log('新题')

            //文本题目 //图片路径重新处理
            var reg = new RegExp('src="','g')
            // 题目
            var question_text = nn.slice(0, d)[index].question_text.replace(reg, 'src="' + urltext2)
            // 选项
            var options_json = nn.slice(0, d)[index].options_json.replace(reg, 'src="' + urltext2)
            // 答案
            var answer = nn.slice(0, d)[index].answer.replace(reg, 'src="' + urltext2)
            // 解析
            var analysis = nn.slice(0, d)[index].analysis.replace(reg, 'src="' + urltext2) 
            
            // console.log(that.paperlist[index].question_text)
            
            nn.slice(0, d)[index].question_text = question_text
            nn.slice(0, d)[index].options_json = options_json

            nn.slice(0, d)[index].analysis = analysis
            if(analysis){

            } else {
              nn.slice(0, d)[index].analysis = '略'
            }
            nn.slice(0, d)[index].answer = "<div>答案:</div>"+ answer + "<div>解析:</div>" + nn.slice(0, d)[index].analysis

            that.nums++
          }
          //题型难度  转换
          that.gettypelist.forEach(function (data1) {
            if (data.question_type == data1.type_id) {
              nn.slice(0, d)[index].question_type = data1.type_name
            }
          })
          that.formatypearr.forEach(function (data2) {
            if (data.question_level == data2.fmttype_id) {
              nn.slice(0, d)[index].question_level = data2.fmttype_name
            }
          })
          that.paperlist.push(data)
        })
        that.sjlength = that.sjlength + Number(nn.slice(0, d).length),
        that.tablelist.push({
          tixing: f.tixing,
          jh: d,
          sj: Number(nn.slice(0, d).length),
        })
        that.papernum = that.papernum + Number(nn.slice(0, d).length)

        // MathJax.Hub.Queue(['Typeset', MathJax.Hub])
        // window.MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById('homeright')]);
      })
    },

    //章节题型筛选
    zjgid(val) {
      this.chapteriddata.gid = val
      this.getQuestionTypeCopy(this.chapteriddata)
      this.sxpaperlistCopy = []
      this.tablelistCopy = []
    },
    zjsidCopy(val) {
      this.chapteriddata.sid = val
      this.getQuestionTypeCopy(this.chapteriddata)
      this.sxpaperlistCopy = []
      this.tablelistCopy = []
    },
    //章节试题筛选
    chapterid(val) {
      this.chapteridtoggle = val
      this.paperdataCopy.chapters = val
    },
    questionnumCopy(val) {
      var that = this
      that.tablelistCopy = []
      that.paperlistCopy = []
      that.sjlengthCopy = 0
      that.papernum = 0
      that.numsCopy = 0
      that.numsCopy1 = 0
      val.forEach(function (res,index) {
        that.paperdataCopy.type = res.id
        that.paperlistconhttp(that.paperdataCopy,res.sun,val[index])
      })
      if(val[0] == undefined){
        that.table = false
      } else {
        that.table = true
      }
    },
    getQuestionTypeCopy(e) {
      //获取章节题库题型
      var data = {
        sid: e.sid,
        gid: e.gid,
      }
      this.$post(URL.getQuestionType, data).then((res) => {
        this.gettypelistCopy = []
        this.gettypelistCopy = this.gettypelistCopy
          .concat(res.result)
      })
    },
    // 章节试题列表
    paperlistconhttp(e, d,f) {
      var that = this
      var data = {
        type: e.type,
        level: e.level,
        chapters: e.chapters,
        page: e.page,
        num: e.num,
      }
      this.$post(URL.getChaptersQues, data).then((res) => {
        var arr = res.result.rows
        var nn = []
        for (var i = 0; i < arr.length; i++) {
          var item = arr[Math.floor(Math.random() * arr.length)]
          nn.push(item)
        }
        // that.paperlist = 
        // html地址解析
        nn.slice(0, d).forEach(function (data, index) {
          var html = data.question_text

          var urltext2 = "http://rjwtiku.oss-cn-beijing.aliyuncs.com"
          // store_type 1新 2旧题 判断
          if (data.store_type == 2) {
            var urltext = html.match(/(\S*)SYS/)[1]
            that.$get(data.answer).then((res) => {
              var reg = new RegExp('src="', "g")
              that.realurl2 = res.replace(reg, 'src="' + urltext)
              nn.slice(0, d)[index].answer = that.realurl2
              that.numsCopy++
            })
            that.$get(data.question_text).then((res) => {
              var reg = new RegExp('src="', "g")
              that.realurl2 = res.replace(reg, 'src="' + urltext)
              nn.slice(0, d)[index].question_text = that.realurl2
              that.numsCopy++
            })
            that.numsCopy1++
          } else {
            // console.log('新题')

            //文本题目 //图片路径重新处理
            var reg = new RegExp('src="','g')
            // 题目
            var question_text = nn.slice(0, d)[index].question_text.replace(reg, 'src="' + urltext2)
            // 选项
            var options_json = nn.slice(0, d)[index].options_json.replace(reg, 'src="' + urltext2)
            // 答案
            var answer = nn.slice(0, d)[index].answer.replace(reg, 'src="' + urltext2)
            // 解析
            var analysis = nn.slice(0, d)[index].analysis.replace(reg, 'src="' + urltext2) 
            
            // console.log(that.paperlist[index].question_text)
            
            nn.slice(0, d)[index].question_text = question_text
            nn.slice(0, d)[index].options_json = options_json

            nn.slice(0, d)[index].analysis = analysis
            if(analysis){

            } else {
              nn.slice(0, d)[index].analysis = '略'
            }
            nn.slice(0, d)[index].answer = "<div>答案:</div>"+ answer + "<div>解析:</div>" + nn.slice(0, d)[index].analysis

            that.numsCopy++
          }
          //题型难度  转换
          that.gettypelistCopy.forEach(function (data1) {
            if (data.question_type == data1.type_id) {
              nn.slice(0, d)[index].question_type = data1.type_name
            }
          })
          that.formatypearr.forEach(function (data2) {
            if (data.question_level == data2.fmttype_id) {
              nn.slice(0, d)[index].question_level = data2.fmttype_name
            }
          })
          that.paperlistCopy.push(data)
        })
        that.sjlengthCopy = that.sjlengthCopy + Number(nn.slice(0, d).length)
        that.tablelistCopy.push({
          tixing: f.tixing,
          jh: d,
          sj: Number(nn.slice(0, d).length),
        })
        that.papernum = that.papernum + Number(nn.slice(0, d).length)
      })
    },
  },
};
</script>

<style lang="less" scoped>
.zjleft {
  width: 19%;
}
.zjright {
  width: 77%;
}
.nav p {
  margin-top: 24px;
  width: 110px;
  line-height: 32px;
  color: #2f2f2f;
  text-align: center;
  font-size: 16px;
  background-color: #e8e7e7;
  border-radius: 5px;
  cursor: pointer;
}
.active {
  background-color: #378aff !important;
  color: #fff !important;
}

</style>
